<template>
  <b-sidebar
      id="stock-stockcard-detail"
      bg-variant="white"
      sidebar-class="sidebar-lg"
      shadow
      backdrop
      no-header
      right
  >
    <div>
      {{stockCard}}
    </div>
  </b-sidebar>
</template>

<script>
export default {
  name: "StockCardDetail--",
  props: {
    stockCard: {
      required: true,
      type: Object
    }
  }
}
</script>

<style scoped>

</style>
