<template>
    <b-overlay
            :show="show"
            rounded="sm"
    >
        <b-card
                no-body
                class="mb-1"
        >
            <div class="m-1">
                <b-form @submit.prevent>
                    <b-row>
                        <b-col md="3">
                            <b-row>
                                <b-col md="6" class="m-0 p-0">
                                    <b-form-input type="text" size="sm" v-model="_search.product.id"
                                                  placeholder="Ürün Id"/>
                                    <b-form-input type="text" size="sm" v-model="_search.product.barcode"
                                                  placeholder="Ürün Barcode"/>
                                </b-col>
                                <b-col md="6" class="m-0 p-0">
                                    <b-form-input type="text" size="sm" v-model="_search.product.name"
                                                  placeholder="Ürün Adı"/>
                                    <b-form-input type="text" size="sm" v-model="_search.shelve_name"
                                                  placeholder="Raf Adı"/>
                                </b-col>
                            </b-row>
                        </b-col>
                        <b-col md="8">
                            <b-row>
                                <b-col md="2" class="m-0 p-0">
                                    <v-select
                                            v-model="_search.suppliers"
                                            label="name"
                                            multiple
                                            :options="_suppliers"
                                            :reduce="item => item.id"
                                            class="select-size-sm"
                                            placeholder="Tedarikçi"
                                            :closeOnSelect="false"
                                    >
                                    </v-select>
                                </b-col>
                                <b-col md="2" class="m-0 p-0">
                                </b-col>
                                <b-col md="2" class="m-0 p-0">
                                    <v-select
                                            v-model="_search.stock_return"
                                            label="name"
                                            multiple
                                            :options="stock_return"
                                            :reduce="item => item.id"
                                            class="select-size-sm"
                                            placeholder="Stok / İade"
                                    />
                                </b-col>
                                <b-col md="2" class="m-0 p-0">
                                    <v-select
                                            v-model="_search.sorting"
                                            label="key"
                                            :options="sortingList"
                                            :reduce="item => item.val"
                                            class="select-size-sm"
                                            placeholder="Sıralama"
                                    />
                                    <v-select
                                            v-model="_search.orderBy"
                                            label="key"
                                            :options="orderByList"
                                            :reduce="item => item.val"
                                            class="select-size-sm"
                                            placeholder="Sır. Tarihi"
                                    />
                                </b-col>
                                <b-col md="2" class="m-0 p-0">

                                </b-col>
                                <b-col md="2" class="m-0 p-0">
                                    <v-select
                                            v-model="_search.limit"
                                            :options="[1,5,10,20,50,100,250,500]"
                                            class="select-size-sm"
                                            placeholder="Limit"
                                    />
                                </b-col>
                            </b-row>
                        </b-col>
                        <b-col md="1">
                            <b-button-group size="sm">
                                <b-button
                                        variant="primary"
                                        @click="getAll(1)"
                                        title="Getir"
                                        type="submit"
                                >
                                    <feather-icon icon="FilterIcon"></feather-icon>
                                </b-button>
                                <b-button
                                        variant="danger"
                                        @click="clearSearch"
                                        title="Temizle"
                                        type="reset"
                                >
                                    <feather-icon icon="XCircleIcon"></feather-icon>
                                </b-button>
                                <b-button variant="info" @click="getToExcel" title="Excele Çıkar"
                                          :disabled="isGetToExcelBtn">
                                    <feather-icon icon="FileIcon"></feather-icon>
                                </b-button>
                            </b-button-group>
                        </b-col>
                    </b-row>
                </b-form>
                <Pagination :pagination="_pagination" @pageClick="getAll"/>

                <b-row>
                    <b-col>
                        <b-form-checkbox
                                style="display: inline"
                                class="custom-control-success"
                                v-if="_stocks.length>0"
                                v-model="isSelectedAll"
                                @change="selectAll"
                        >
                            Tümünü Seç ({{ _selected.length }})
                        </b-form-checkbox>
                        <b-button
                                v-if="_selected.length"
                                variant="secondary"
                                size="sm"
                                style="display: inline"
                                @click="allDelete"
                        >
                            Tümünü Sıfırla
                        </b-button>
                    </b-col>
                </b-row>
            </div>
        </b-card>
    </b-overlay>
</template>

<script>
import {mapGetters} from "vuex";
import Pagination from "@/components/Pagination";
import vSelect from "vue-select";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import axios from "@/libs/axios";

export default {
    name: "StockSearch",
    components: {Pagination, vSelect},
    data: () => ({
        isGetToExcelBtn: false,
        show: false,
        stock_return: [{id: 9, name: 'Stok'}, {id: 27, name: 'İade'}],
        sortingList: [{key: 'A-Z', val: 'ASC'},
            {key: 'Z-A', val: 'DESC'},],
        orderByList: [
            {key: 'Ürün ID', val: 'product_id'},
            {key: 'Raf Adı', val: 'shelve_name'},
            {key: 'Adet', val: 'quantity'},
            {key: 'Fiyat', val: 'price'},
            {key: 'Ekleme Tarihi', val: 'created_at'},
            {key: 'Güncelleme Tarihi', val: 'updated_at'},
        ],
    }),
    computed: {
        ...mapGetters("stockV2", ["_stocks", "_pagination", "_search", "_selected"]),
        ...mapGetters("supplier", ["_suppliers"]),
        isSelectedAll: {
            get() {
                if (this._stocks.length <= this._selected.length) {
                    return true;
                }
                return false;
            },
            set() {

            }
        },
    },
    methods: {
        getAll(page) {
            this.show = true
            this._search.page = page;
            this.$store.dispatch('stockV2/getAll')
                .then(res => {
                    this.show = false;
                });
        },
        clearSearch() {

        },
        getToExcel() {
            this.isGetToExcelBtn = true;
            axios.post('/v2/stock/getexcel', this._search, {responseType: 'blob'})
                .then(response => {
                    const blob = new Blob([response.data], {
                        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                    })
                    const link = document.createElement('a')
                    link.href = URL.createObjectURL(blob)
                    link.download = "stoklar"
                    link.click()
                    URL.revokeObjectURL(link.href)
                    this.isGetToExcelBtn = false;
                });
        },
        allDelete() {
            this.$swal({
                title: 'Silmek istediğinizden emin misiniz?',
                text: "Bu işlemi geri alamazsınız",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Evet, Sil',
                cancelButtonText: 'Vazgeç',
                customClass: {
                    confirmButton: 'btn btn-primary',
                    cancelButton: 'btn btn-outline-danger ml-1',
                },
                buttonsStyling: false,
            }).then(result => {
                if (result.value) {
                    this.$store.dispatch('stock/delete', this._selected)
                        .then(res => {
                            this.$toast({
                                component: ToastificationContent,
                                props: res
                            })
                        })
                }
            })
        },
        selectAll(event) {
            let selected = [];
            if (event) {
                this._stocks.forEach(stock => {
                    selected.push(stock.id);
                })
            }

            this.$store.state.stockV2.selected = selected;
        }
    }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
